<template>
	<div>
		<a-spin :spinning="loading">
			<a-row>
				<a-col :span="18">
					<a-button @click="onSearchBtn">
						<template #icon><Icon icon="SearchOutlined"/></template>
						查询</a-button>
					<exportModal class="ui-exportBrn" v-permission="['pc_inventory_result_export']" :type="type" :searchData="selIdsData" :condition="condition" :role="role" :isAll="1">
						<a-button>
							<template #icon><Icon icon="UploadOutlined"/></template>
							导出</a-button>
					</exportModal>
					<exportModal class="ui-exportBrn" style="margin-right: 16px;" v-permission="['pc_inventory_result_export']" :type="type" :searchData="searchData" :condition="condition" :role="role" :isAll="0">
						<a-button>
							<template #icon><Icon icon="LogoutOutlined"/></template>
							导出全部</a-button>
					</exportModal>
					<a-button @click="onSearch">
						<template #icon><Icon icon="ReloadOutlined"/></template>
						刷新</a-button>
					<a-button @click="onSort">
						<template #icon><Icon icon="OrderedListOutlined"/></template>
						排序</a-button>
				</a-col>
				<a-col :span="6" style="text-align: right;">
				</a-col>
			</a-row>
			
			<div v-show="isSearch">
				<a-form class="label-width ui-form" ref="formRef" :model="formModal" layout="inline">
					<a-form-item label="盘点任务名称" class="ui-form__item">
						<a-input v-model:value="formModal.taskName"></a-input>
					</a-form-item>
					<a-form-item label="状态" class="ui-form__item">
						<a-select v-model:value="formModal.status"  allow-clear style="width: 190px;">
							<!-- <a-select-option v-for="(item, index) in $store.state.count.useStatusList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option> -->
							<!-- <a-select-option :value="-1">全部</a-select-option> -->
							<a-select-option :value="0">进行中</a-select-option>
							<a-select-option :value="1">已结束</a-select-option>
						</a-select>
					</a-form-item>
					<a-form-item class="ui-form__item">
						<div style="text-align: center;">
							<a-button @click="onReset">重置</a-button>
							<a-button type="primary" @click="onSearch">搜索</a-button>
						</div>
					</a-form-item>
				</a-form>
			</div>
			
			<div v-show="isSort" class="ui-form" style="padding-bottom: 20px;">
				<a-row style="align-items: center;">
					<a-col :span="18">
						<a-checkbox-group v-model:value="sortList">
							<a-checkbox :value="'taskName'">盘点任务名称</a-checkbox>
							<a-checkbox :value="'state'">状态</a-checkbox>
						</a-checkbox-group>
					</a-col>
					<a-col :span="6" style='text-align: right;'>
						<a-button type="primary" @click="onSortClick('asc')">升序</a-button>
						<a-button type="primary" @click="onSortClick('desc')">降序</a-button>
						<a-button type="primary" @click="onResetSortClick()">重置</a-button>
					</a-col>
				</a-row>
			</div>
			
			<div style="margin-top: 20px;">
				<c-Table ref="cTable" :columns="columns" :isRequest="true" :requestFun="getDataFun" rowSelectionType="checkbox" :searchData="searchData" rowKey="id" @rowSelected="rowSelected" :scroll="{ x: 1500 }" bordered draggable>
					<template #bodyCell="{ column, record, index, pagination }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'status'">
							<a-button v-if="record.status === 0" type="link" size="small" >进行中</a-button>
							<a-button v-if="record.status === 1" type="link" size="small" >已结束</a-button>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_inventory_dispatch']" type="link" size="small" @click="onGotoProgress(record)">单位盘点明细</a-button>
						</template>
					</template>
				</c-Table>
				
				<!-- <a-table rowKey="taskId" class="table"
					:columns="columns" 
					:dataSource="list" 
					:pagination="pagination" bordered 
					size="small"
					:scroll="{ x: 1500 }"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'index'">
							{{ ((pagination.current - 1) * pagination.pageSize) + index + 1 }}
						</template>
						<template v-if="column.key === 'status'">
							<a-button v-if="record.status === 1" type="link" size="small" >进行中</a-button>
							<a-button v-if="record.status === 0" type="link" size="small" >已结束</a-button>
						</template>
						<template v-if="column.key === 'action'">
							<a-button v-permission="['pc_inventory_dispatch']" type="link" size="small" @click="onGotoProgress(record.id)">单位盘点明细</a-button>
						</template>
					</template>
				</a-table> -->
			</div>
		</a-spin>
		<profitModal ref="profitModalRef"></profitModal>
	</div>
</template>

<script>
	import { getFiscalResultList } from '@/service/modules/inventory.js';
	import { Icon } from '@/components/icon/icon.js';
	import cTable from '@/components/cTable/index.vue';
	import exportModal from '@/components/exportModal/exportModal.vue';
	export default {
		components: { Icon, cTable, exportModal },
		data() {
			return {
				loading: false,
				isSearch: false,
				isSort: false,
				searchData: {},
				formModal: {
					taskName: ''
				},
				list: [],
				type: 'inventoryResultStrategy', // 导出类型
				role: 1, // 导出角色
				condition: '3', // 导出条件
				selIdsData: {},
				sortList:[],
				getDataFun: getFiscalResultList,
				// taskName: '', // 当前任务名称
				columns: [{
					title: '行号',
					align: 'center',
					key: 'index'
				}, {
					title: '盘点任务名称',
					align: 'center',
					dataIndex: "taskName"
				},{
					title: '应盘资产（件）',
					align: 'center',
					dataIndex: "assetsNum"
				}, {
					title: '已盘资产（件）',
					align: 'center',
					dataIndex: "alreadyNum"
				}, {
					title: '盘亏资产（件）',
					align: 'center',
					dataIndex: "lossNum"
				}, {
					title: '盘盈资产（件）',
					align: 'center',
					dataIndex: "profitNum"
				},  {
					title: '应盘资产总价值（元）',
					align: 'center',
					dataIndex: "value"
				}, {
					title: '盘亏资产总价值（元）',
					align: 'center',
					dataIndex: "lossValue"
				}, {
					title: '盘盈资产总价值（元）',
					align: 'center',
					dataIndex: "profitValue"
				}, {
					title: '状态',
					align: 'center',
					key: 'status',
					dataIndex: "status"
				}, {
					title: '操作',
					align: 'center',
					key: 'action',
					fixed: 'right',
					width: 120
				}],
			}
		},
		created() {
			if(this.$route.query.name) this.formModal.taskName =  this.$route.query.name
			this.onSearch()
		},
		methods: {
			// async getData() {
			// 	this.loading = true;
			// 	try {
			// 		let ret = await getFiscalResultList({
			// 			page: this.pagination.current,
			// 			pageSize: this.pagination.pageSize,
			// 			taskName: this.taskName,
			// 			...this.searchData
			// 		});
			// 		this.loading = false;
			// 		if (ret.code === 200) {
			// 			console.log("resList",ret)
			// 			this.list = ret.data.list;
			// 			this.pagination.total = ret.data.totalCount;
			// 		}
			// 	} catch(e) {
			// 		this.loading = false;
			// 	}
			// },
			onReset() {
				this.$refs.formRef.resetFields();
				this.formModal = {}
				this.onSearch();
			},
			onSearch() {
				this.searchData = JSON.parse(JSON.stringify(this.formModal));	
				// this.pagination.current = 1;
				// this.getData();
				this.$nextTick(() => {
					this.$refs.cTable.toQuery();
				})
			},
			onSearchBtn() {
				this.isSearch = !this.isSearch;
			},
			rowSelected(item) {
				this.selIdsData.ids = item.keys.join(',')
			},
			onSort() {
				this.isSort = !this.isSort;
			},
			onProfit() {
				this.$refs.profitModalRef.open()
			},
			onGotoProgress(item) {
				this.$router.push({
					// name: 'inventoryManageTack',
					name: 'inventoryManageTaskProgress',
					query: {
						id: item.taskId,
						taskName: item.taskName
					}
					// query: {
					// 	id: id
					// }
				})
			},
			onSortClick(type){
				let list = [];
				this.sortList.forEach(element => {
					list.push({key:element,sort:type});
				});
				this.formModal.sort = list;
				console.log(this.formModal);
				this.onSearch();
			},
			onResetSortClick(){
				this.sortList = [];
				this.formModal.sort = undefined;
				this.onSearch();
			}
		}
	}
</script>

<style scoped>
	.ui-form {
		margin-top: 20px;
		padding: 20px 20px 0;
		background: #F5F7FA;
	}
	.ui-form__middle {
		padding-top: 4px;
		text-align: center;
	}
	.ui-exportBrn {
		margin-left: 16px;
	}
</style>